import { Space, Table, Modal } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '../../../../assets/Icon/DeleteIcon';
import GearIcon from '../../../../assets/Icon/GearIcon';
import PencilIcon from '../../../../assets/Icon/PencilIcon';
import Col from '../../../components-v2/Col';
import Divider from '../../../components-v2/Divider';
import Row from '../../../components-v2/Row';
import { NotificationTypes, showNotification } from '../../../components/Notifications';
import apiClient from '../../../utils/apiClient';

const SavedImportSettings = (): React.ReactElement => {
  const history = useHistory();
  const [channelsData, setChannelsData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [deleteGuid, setDeleteGuid] = React.useState<string>('');
  React.useEffect(() => {
    void getTenantSpecificChannels();
  }, []);

  const getTenantSpecificChannels = async () => {
    try {
      setLoading(true);
      const { data } = await apiClient.get('/data-ingest/configs/tenant-specific-channels');
      setChannelsData(data.response);
    } catch (error) {
      console.log(error);
      showNotification(
        NotificationTypes.error,
        'Getting Saved Import Settings Failed',
        'Error occurred while getting saved import settings from server',
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteTenantSpecificChannel = async (guid: string) => {
    try {
      const { data } = await apiClient.delete(`/data-ingest/configs/delete/${guid}`);
      await getTenantSpecificChannels();
      if (data.response) {
        showNotification(
          NotificationTypes.success,
          'Deleted Saved Import Setting',
          'Successfully deleted saved import setting',
        );
      } else {
        showNotification(
          NotificationTypes.error,
          'Delete Saved Import Setting Failed',
          'Error occurred while deleting saved import setting',
        );
      }
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Delete Saved Import Setting Failed',
        'Error occurred while deleting saved import setting',
      );
    }
  };

  const tableColumns = [
    {
      title: 'Import Name',
      dataIndex: 'importName',
      key: 'importName',
    },
    {
      title: 'Import Type',
      dataIndex: 'importType',
      key: 'importType',
    },
    {
      title: 'Last Import',
      dataIndex: 'lastImport',
      key: 'lastImport',
    },
    {
      title: 'Last Edited',
      dataIndex: 'lastEdited',
      key: 'lastEdited',
    },
    {
      title: 'Actions',
      render: (row) => {
        return (
          <div key={row.lastImport + row.importType} className="actionsIcon">
            <div
              style={{ display: 'inline-block' }}
              onClick={() => {
                console.log('route to edit clicked', row.guid);
              }}
            >
              <PencilIcon />
            </div>
            <div
              style={{ display: 'inline-block' }}
              onClick={() => {
                setShowModal(true);
                setDeleteGuid(row.channelGuid);
              }}
            >
              <DeleteIcon />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="new-data-import mainMatchFieldsSection mainSaveImportSettings">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleField" data-cy="data-import-heading" level={1}>
              Saved Import Settings
            </Title>
          </Col>
          <Col span="auto">
            <Space>
              <button className="button">
                Configure <GearIcon />
              </button>
              <button onClick={() => history.push('/scholarships/new-data-import')} className="continue-btn">
                New Data import
              </button>
            </Space>
          </Col>
        </Row>
        <Divider className="divider-data" />
      </div>
      <div className="mainWhitebgWrapper">
        <Row className="mainPerformTimeWrapper">
          <Col span={24}>
            <div>
              <p>
                Saved Import can be used when manually importing files into Naviance. Saved imports are used when
                sending files to Naviance via SFTP or cURL.
              </p>
            </div>
            <div className="mainDataImportTable">
              <Table
                rowKey="id"
                columns={tableColumns}
                dataSource={channelsData}
                pagination={false}
                loading={loading}
              ></Table>
            </div>
            <Modal
              title="Delete Saved Import Setting"
              open={showModal}
              onOk={async () => {
                setShowModal(false);
                await deleteTenantSpecificChannel(deleteGuid);
              }}
              onCancel={() => {
                setShowModal(false);
              }}
              okText="Confirm"
              cancelText="Cancel"
              centered
            >
              <p>Are you sure you want to delete this setting</p>
            </Modal>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default SavedImportSettings;
